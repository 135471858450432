import React from 'react'

export default () => (
  <div className="p-8 rounded-lg shadow-md bg-white w-full">
    <h3 className="text-xl font-bold mb-2">Follow along</h3>
    <p>Sign up to receive periodic updates on our progress.</p>

    <form action="https://gmail.us5.list-manage.com/subscribe/post?u=8679663dabe024a27d848834b&amp;id=6ac7bd1510" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div className="flex flex-wrap items-center">
        <input
          type="email"
          name="EMAIL"
          placeholder="address@website.com"
          className="w-full md:flex-1 mb-4 md:mb-0 border borer-gray-600 text-xl rounded w-full py-2 px-4 focus:outline-none focus:shadow focus:border-primary-800 mr-4"
        />
        <button type="submit" className="btn w-auto">
          Sign Up
        </button>
      </div>
    </form>
  </div>
)