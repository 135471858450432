import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Nav from './nav'
import SignUp from './sign-up'
import './layout.css'

const Layout = ({ children, navClass }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "images/silly family.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Nav navClass={navClass} />
      <main>{children}</main>
      <BackgroundImage
        Tag="footer"
        fluid={data.bgImage.childImageSharp.fluid}
        className="py-24 lg:py-40 bg-primary-1000"
      >
        <div className="w-full max-w-4xl px-8 mx-auto">
          <div className="flex flex-wrap items-center -mx-6">
            <div className="w-full md:w-2/3 px-6 mb-8 md:mb-0">
              <SignUp />
            </div>
            <div className="w-full md:w-1/3 px-6">
              <Link to="/" className="footer-link">Home</Link>
              <Link to="/donate" className="footer-link">Donate</Link>
              <Link to="/updates" className="footer-link">Updates</Link>
              <a className="footer-link" href="https://www.bonfire.com/store/holle-adoption/" rel="noopener noreferrer" target="_blank">Buy a t-shirt</a>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
