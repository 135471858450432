import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/logo.svg'

export default ({ navClass }) => (
  <nav className={`top-nav ${navClass}`}>
    <Link
      className="block w-24 h-24"
      to="/"
    >
      <Logo />
    </Link>

    <div className="flex items-center">
      <Link to="/updates" className="top-nav-link">Updates</Link>
      <Link to="/donate" className="btn small normal-case ml-4">Donate</Link>
    </div>
  </nav>
)
